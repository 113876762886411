import { fetchSupplementPage, handleSupplementAdd, handleSupplementDelete } from 'api/setting';
// import Pagination from '@/components/pagination';
export default {
  components: {
    // Pagination,
  },
  data() {
    return {
      filterForm: {
        page: 1,
        pageSize: 20,
      },
      total: 0,
      postData: {},
      list: [],
      loading: {
        list: false,
      },
      dialog: {
        visible: false,
        title: '--',
        type: '',
      },
      rules: {
        prod: [{ required: true, message: 'prod不能为空' }],
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 获取列表
    getList(page) {
      if (page) {
        this.filterForm.page = page;
      }
      this.loading.list = true;
      fetchSupplementPage(this.filterForm)
        .then((res) => {
          if (res.code == 200) {
            const results = res.result;
            var resultsArray = [];
            for (let i in results) {
              resultsArray.push({
                id: '',
                prod: i,
                createTime: results[i],
              });
            }
            this.list = resultsArray;
            this.total = res.total;
          } else {
            this.$message.error(res.message);
          }
          this.loading.list = false;
        })
        .catch((e) => {
          this.loading.list = false;
          this.$message.error(e);
        });
    },
    // 删除
    deletes(id) {
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          handleSupplementDelete(id).then((res) => {
            if (res.code == 200) {
              this.$message.success('删除成功！');
              this.getList();
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    // 打开弹窗
    openDialog(type, row) {
      this.dialog.type = type;
      this.dialog.title = type == 'add' ? '添加' : '编辑';
      this.dialog.visible = true;
      if (row) {
        this.postData = Object.assign({}, row);
      }
    },
    // 提交弹窗
    submitDialog(formName) {
      console.log(formName);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading.submitBtn = true;
          if (this.dialog.type == 'add') {
            console.log('添加操作');
            handleSupplementAdd(this.postData).then((res) => {
              if (res.code == 200) {
                this.$message.success('添加成功!');
                this.loading.submitBtn = false;
                this.closeDialog(formName);
                this.getList();
              } else {
                this.$message.error(res.message);
                this.loading.submitBtn = false;
              }
            });
          }
          if (this.dialog.type == 'edit') {
            console.log('编辑操作');
          }
        }
      });
    },
    // 关闭弹窗
    closeDialog(formName) {
      this.$refs[formName].resetFields();
      this.dialog.visible = false;
    },
    handleSizeChange(size) {
      this.filterForm.pageSize = size;
      this.handleCurrentChange(1);
    },
    handleCurrentChange(currentPage) {
      this.filterForm.page = currentPage;
      this.getList();
    },
  },
};
